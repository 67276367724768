<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="classroomSection === 1" :title-value="'Classrooms'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-bus-school</v-icon>
        <v-toolbar-title class="pl-2">Classrooms</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <!--Clear Filter Button -->
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="classroomsStore.classrooms.data && classroomsStore.classrooms.data.length > 0"
      :showTotal="true"
      :currentPage="classroomsStore.classrooms.current_page"
      :lastPage="classroomsStore.classrooms.last_page"
      :total="classroomsStore.classrooms.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="classroomsStore.classrooms.data && classroomsStore.classrooms.data.length > 0"
        :list-data="classroomsStore.classrooms.data"
        :title="'Classrooms'"
        :section="34"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "classroomsStore/getFilterField",
  mutationType: "classroomsStore/updateFilterField"
});

export default {
  name: "Classrooms",
  components: {
    TitleBar,
    Pagination,
    ListBasic
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      classroomsStore: state => state.classroomsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields(["page", "fk_providerID", "fk_teacherID", "f_status"])
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Teacher Sublist = 3 */
    classroomSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      filterMenu: false
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.classroomSection === 2) {
        /* Grab provider detail */
        const provider = this.$store.getters["providersStore/getProvider"];
        /* Hard code provider ID to restrict by provider */
        this.fk_providerID = provider.id;
        this.fk_teacherID = "";
      }
      else if(this.classroomSection === 3) {
        /* Grab teacher detail */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        /* Hard code teacher ID to restrict by provider */
        this.fk_providerID = ""
        this.fk_teacherID = teacher.id;
      }
      /* Grab classroom list */
      const filters = this.$store.getters["classroomsStore/getFilters"];
      await this.$store.dispatch("classroomsStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      const data = {
        id: [1, 34]
      };
      await this.$store.dispatch("valueListsStore/items", data);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.f_status = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    async openEntry(id) {
      await this.loadValueLists();
      await this.$store.dispatch("classroomsStore/entry", id);
    },

    showFiltered() {
      if (this.f_status || this.f_status === 0) {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
